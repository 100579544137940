import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import './index.scss';

const Tabs = (props = {}) => {
  // eslint-disable-next-line react/prop-types
  const { list = [], onChange = () => {}, defaultIndex = 0 } = props;

  const [activeIndex, setActiveIndex] = useState(defaultIndex);

  const [lineLeft, setLineLeft] = useState(null);

  const [sublineWidth, setSublineWidth] = useState(104);

  const tabsRef = useRef();

  const onClick = useCallback(
    (item, index) => {
      onChange(item, index);
      setActiveIndex(index);
    },
    [onChange],
  );

  useEffect(() => {
    const activeDom = tabsRef.current.querySelector('.tabs-item.active');
    const textDom = tabsRef.current.querySelector('.tabs-item.active>span');
    if (!activeDom) return;
    const left = activeDom.offsetLeft + activeDom.offsetWidth / 2 - textDom.offsetWidth / 2 - 12;
    setLineLeft(left);
    setSublineWidth(textDom.offsetWidth + 40);
  }, [activeIndex]);

  useEffect(() => {
    setActiveIndex(defaultIndex);
  }, [defaultIndex]);

  return (
    <div className="cp-tabs" ref={tabsRef}>
      <div className="tabs-content">
        {list.map((item, index) => (
          <div
            onClick={() => onClick(item, index)}
            className={`tabs-item ${activeIndex === index ? 'active' : ''}`}
            key={item.id}
            role="button"
            tabIndex="0"
          >
            <span>{item.name}</span>
          </div>
        ))}
      </div>
      <div
        className="tabs-subline"
        style={{
          left: lineLeft,
          width: sublineWidth,
        }}
      />
    </div>
  );
};

// Tabs.propTypes = {
//   onChange: Function,
//   defaultIndex: Number,
// };

// Tabs.defaultProps = {
//   // eslint-disable-next-line react/default-props-match-prop-types
//   list: [],
//   onChange: () => {},
//   defaultIndex: 0,
// };

export default memo(Tabs);
