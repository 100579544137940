import React, { memo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Lazy } from 'swiper';
import 'swiper/swiper.scss';
import { uuid, splitArry } from '@/utils/tools';
import style from './index.module.scss';

SwiperCore.use([Autoplay, Lazy]);

const commentsList = splitArry(
  [
    {
      avator:
        '//img10.360buyimg.com/paipai/jfs/t1/179604/30/19514/10649/611a1e3aE4a16b6ec/e5fc092f1e8509d8.png',
      isVip: true,
      name: 'e***1',
      brand: '荣耀V30 PRO(5G)',
      comment:
        '机器收到出乎意料，写的9新，但起码95新，虽然是第一批机器，不过官方保修还有3个月，店里保修一年，拍拍严选不错，别人都是保修半年。主要是还有检测报告，非常直观，很好，以后还会在这买机器',
    },
    {
      avator:
        '//img10.360buyimg.com/paipai/jfs/t1/199818/38/3339/4105/611a0087Eb516ca39/600b67b557fe4344.png',
      isVip: true,
      name: '****0',
      brand: '三星 Galaxy S10',
      comment:
        '三星手机我一直都是从拍拍家买的，性价比巨好，官方翻新和新的一样，关键都是原装配件，128g足够我用了，哈哈，屏幕也很棒，这个价格太牛了，一年的保修给我最大的保障，家里的手机都是从拍拍家买的，太放心了，推荐给大家',
    },
    {
      avator:
        '//img10.360buyimg.com/paipai/jfs/t1/179604/30/19514/10649/611a1e3aE4a16b6ec/e5fc092f1e8509d8.png',
      isVip: false,
      name: '王***力',
      brand: 'iPhone XR',
      comment:
        '收到了 跟新机器差不多 使用了两天 特别给力 电池88容量 处理器好可以 不卡顿 从来没用过二手的 本来打算试一试的心态 买了一个 还真不错 太可以啦 划算 以后买二手手机就你家了 哈哈哈哈 太给力了 而且还有一年的包修包换的 包括电池 反正这次购物体验很棒 划算',
    },

    {
      avator:
        '//img10.360buyimg.com/paipai/jfs/t1/190878/8/18748/11335/611a2372E44d81d7d/6e952d5487c871db.png',
      isVip: false,
      name: '1***p',
      brand: 'iPhone 12 Pro Max',
      comment:
        '奈斯！机子简直就是全新未开封的 各项数据完美，外观方面就是全新的一样 拐角无磕碰 屏幕没有划痕，刚回来就试机了 是真的完美 刚开始确实挺忐忑不安的 第一次京东买这么贵的二手手机，以前都是买京东自营全新机 一直挺好，这次看了很久，都说拍拍二手挺靠谱，没想到的确没错 大爱',
    },
    {
      avator:
        '//img10.360buyimg.com/paipai/jfs/t1/180505/2/19566/10328/611a237cE6c44d36e/3992caccf1c18874.png',
      isVip: true,
      name: 'm***5',
      brand: 'iPhone 12 Pro Max',
      comment:
        '当拿到机器时，完全就是一台准新机，基本无印迹，买时还心理阴影，拿到手，瞬间消失，爱思全绿，电池100%，充电3 4次，正品国行，包装也很到位，不用担心运输损伤，唯一个缺点就是保修签贴在充电口，不得不撕掉了，不过主要是机器，其他都忽略了。值得推荐给大家。',
    },
    {
      avator:
        '//img10.360buyimg.com/paipai/jfs/t1/181353/11/18719/3843/611a011fEa8cdb103/381642c789aff187.png',
      isVip: true,
      name: 'a***8',
      brand: '华为 HUAWEI P40 Pro(5G)',
      comment:
        '很高兴买到货真价实的正品行货，经过入手已经有差不多两个月有余了，现在才来个晚到的使用感受分享给各位大佬，手机百分百正品官保到五月份，感觉很值，手机的各项功能完美，通过和华为体验店的样机对比完全信服无容致疑，相机拍照非常好，流畅度我个人觉得非常好！！！',
    },

    {
      avator:
        '//img10.360buyimg.com/paipai/jfs/t1/179604/30/19514/10649/611a1e3aE4a16b6ec/e5fc092f1e8509d8.png',
      isVip: true,
      name: '叁***立',
      brand: 'OPPO Find X3',
      comment:
        '给我很大的惊喜，惊喜一原机包装，原机配件全部都有。惊喜二手机没有任何划痕和磕碰，和全新机一样。惊喜三价格必买新机便宜一千多呀。惊喜四手机非常惊艳各方面都非常出色，不亏旗舰机。',
    },
    {
      avator:
        '//img10.360buyimg.com/paipai/jfs/t1/179604/30/19514/10649/611a1e3aE4a16b6ec/e5fc092f1e8509d8.png',
      isVip: false,
      name: 'c***g',
      brand: 'iPhone 12',
      comment:
        '经过多次筛选选中了这家，开始还挺担心二手的产品有问题，使用了四天后，手机质量很好，跟新的没啥区别，总结如下：1.手机的包装精美，保护得很好。2.电池耐用，仍有100%，不打游戏，基本可以用两天了。3.每台手机有检测报告，将手机的情况介绍的很详细。实物与报告符合。4.保修还有10个月，相当于准新机。5.客服非常有礼貌，耐心，问题能一一详细解答',
    },
    {
      avator:
        '//img10.360buyimg.com/paipai/jfs/t1/158523/3/13603/4703/605077e3E99d1962c/fcdc72fbafa6c46f.png',
      isVip: false,
      name: '小***_',
      brand: 'iPhone Xsmax',
      comment:
        '看了好多平台最后选择了京东旗下的拍拍二手优品旗舰店，用了一个星期才来评论的，确实很靠谱，跟图片描述的一模一样，这款苹果xsmxs是最喜欢的一个品牌了，比买新机省了一千多块，话不多说拍拍二手优品旗舰店很靠谱',
    },
  ].map((item) => ({ ...item, _uuid: uuid() })),
  3,
).map((item) => ({
  group: item,
  _uuid: uuid(),
}));

const CommentsSwiper = () => (
  <div className={style.comments}>
    <div className={style.comments_title}>好用的手机没那么贵！</div>
    <div className={style.swiper}>
      <Swiper
        loop
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        {commentsList.map((item) => (
          <SwiperSlide key={item._uuid}>
            <div className={style.comments_group}>
              {item.group.map((item2) => (
                <div className={`${style.comments_item} flex_top_start`} key={item2._uuid}>
                  <div className={style.left}>
                    <img className={style.avator} src={item2.avator} alt="头像" />
                    <div className={style.name}>{item2.name}</div>
                    {item2.isVip && <div className={style.isPlus} />}
                  </div>
                  <div>
                    <div className={style.brand}>
                      <span>购买</span>
                      <span>{item2.brand}</span>
                    </div>
                    <div className={style.bottom}>{item2.comment}</div>
                  </div>
                </div>
              ))}
            </div>
            {/*  */}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  </div>
);

export default memo(CommentsSwiper);
