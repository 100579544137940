import React, { memo, useCallback } from 'react';
import { scrollTo, getScroll } from '@/utils/tools';
import logoImg from '@/static/imgs/logo.png';
// import arrowImg from '@/static/imgs/arrow-right.png';
import style from './index.module.scss';

const navList = [
  {
    name: '爱回收严选',
    id: 'inspect',
  },
  {
    name: '高价回收',
    id: 'huishou',
  },
  {
    name: '夺宝岛',
    id: 'dbd',
  },
];

/**
 * 获取element 在容器中的top位置
 * @param {*} element
 * @param {*} container
 * @returns
 */
function getOffsetTop(element, container) {
  if (!element) {
    return 0;
  }

  if (!element.getClientRects().length) {
    return 0;
  }

  const rect = element.getBoundingClientRect();

  if (rect.width || rect.height) {
    let _container = container;
    if (_container === window) {
      _container = element.ownerDocument?.documentElement;
      return rect.top - (_container?.clientTop || 0);
    }
    return rect.top - _container.getBoundingClientRect().top;
  }

  return rect.top;
}

const Header = () => {
  /**
   * 锚点点击滚动
   * @param {*} id
   */
  const scrollToElm = useCallback((id) => {
    const scrollTop = getScroll(window, true);
    const eleOffsetTop = getOffsetTop(document.getElementById(id), window);
    const y = scrollTop + eleOffsetTop;
    scrollTo(y);
  }, []);

  return (
    <div className={`${style.header} wrap`}>
      <div className={style.top}>
        <a className={style.logo} href="/">
          <img src={logoImg} alt="logo" />
        </a>
        <div className={style.nav}>
          {navList.map((item) => (
            <span
              onClick={() => {
                scrollToElm(item.id);
              }}
              key={item.id}
              tabIndex="0"
              role="button"
              className={style['nav-item']}
            >
              {item.name}
            </span>
          ))}
          {/* <a
            className={`${style['nav-item']} ${style['nav-item_link']}`}
            href="https://2.jd.com/"
            rel="noreferrer"
            clstag="pageclick|keycount|ppgwweb_home_go2|1"
            target="_blank"
          >
            去买二手 <img className={style.arrowRight} src={arrowImg} alt="" />
          </a> */}
        </div>
        <div className={style.banner}>
          <img
            src="//img10.360buyimg.com/paipai/jfs/t1/198311/19/24801/396431/63f5b8adF9c582b4a/ccfab0c1a9ce43bf.png"
            alt=""
            className={style.annouce}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
