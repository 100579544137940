import React, { memo } from 'react';
import Footer from '@/components/footer';
// eslint-disable-next-line no-unused-vars
import DownlosdGuide from '@/components/downlosdGuide';
import Header from './components/header';
import InspectSection from './components/inspectSection';
import HuiShouSection from './components/huishouSection';
import DbdSection from './components/dbdSection';
import style from './index.module.scss';

const Index = () => (
  <div className={style.index}>
    <Header />
    <InspectSection />
    <HuiShouSection />
    <DbdSection />
    <Footer />
    {/* <DownlosdGuide /> */}
  </div>
);

export default memo(Index);
