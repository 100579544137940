import React, { memo } from 'react';
import { uuid } from '@/utils/tools';
import style from './index.module.scss';

/**
 * 夺宝岛
 * @returns
 */

const dbdImages = [
  {
    img: '//img10.360buyimg.com/paipai/jfs/t1/185727/9/19427/124668/611a0af6Ea1565882/32542ca7e5245154.png',
  },
  {
    img: '//img10.360buyimg.com/paipai/jfs/t1/184696/1/19409/136094/611a0ae1Eddf54417/4ff1001c9b5b71d8.png',
  },
  {
    img: '//img10.360buyimg.com/paipai/jfs/t1/193761/33/18447/61466/611a0aceEec733325/d1f4f803becd1794.png',
  },
  {
    img: '//img10.360buyimg.com/paipai/jfs/t1/202455/39/1636/134657/611a0abbEa94f195b/bf0149ad815b3e06.png',
  },
  {
    img: '//img10.360buyimg.com/paipai/jfs/t1/187173/37/18558/77082/611a0b0aE276386f7/a0e8911fe6b43040.png',
  },
].map((item) => ({ ...item, _uuid: uuid() }));

const HuiShouSection = () => (
  <div className={`${style.dbd} wrap`} id="dbd">
    <div className="content">
      <div className={style.title}>
        <span className={style.highRecover}>拍拍夺宝岛</span>
        <div className={style['title-sub']}>
          <span className={style['sub-text']}>全品类大牌特卖</span>
        </div>
      </div>
      <div className={style.bg_box}>
        {dbdImages.map((item) => (
          <img key={item._uuid} className={style.bg} src={item.img} alt="夺宝岛" />
        ))}
      </div>
    </div>
  </div>
);

export default memo(HuiShouSection);
