import React, { memo } from 'react';
import { uuid } from '@/utils/tools';
import style from './index.module.scss';

const categoryList = [
  {
    content: ['专业质检', '180天保修', '7天保价', '7天无理由'],
    img: '//img10.360buyimg.com/paipai/jfs/t1/191472/29/18598/245033/611a0b95E08a7d392/d523e2251734b1c7.png',
  },
  {
    content: ['专业质检', '7天无理由', '免费送货安装', '3年质保'],
    img: '//img10.360buyimg.com/paipai/jfs/t1/199658/39/3380/237118/611a4630E027bf7ff/ad2a9e6476446083.png',
  },
  {
    content: ['双检鉴真', '48H退换货', '假一赔三', '1年质保'],
    img: '//img10.360buyimg.com/paipai/jfs/t1/183006/13/19609/241961/611a0b76E06802095/34529e56370e3504.png',
  },
  {
    content: ['乐器文娱', '母婴玩具', '食品酒水', '京东备件库'],
    img: '//img10.360buyimg.com/paipai/jfs/t1/186947/5/18673/241402/611a0ba5E3003576a/327e24688190d129.png',
  },
].map((item) => ({
  ...item,
  _uuid: uuid(),
}));

const InspectCategory = () => (
  <div className={style.category}>
    {categoryList.map((item) => (
      <div className={style.category_item} key={item._uuid}>
        <div className={style.item_content}>
          {item.content.map((text) => (
            <p className={style.text} key={text}>
              {text}
            </p>
          ))}
        </div>
        <img className={style.img} src={item.img} alt={item.title} />
      </div>
    ))}
  </div>
);

export default memo(InspectCategory);
