import React, { memo } from 'react';
// import CountUp from 'react-countup';
// import { easeInOutCubic } from '@/utils/tools';
import style from './index.module.scss';

const InspectTitle = () => (
  <div className={style.inspect_title}>
    <span className={style.ahsYanxuan}>爱回收严选</span>
    <div className={style['title-sub']}>
      <span className={style['sub-text']}>
        {/* <span>有</span>
        <CountUp start={0} end={150000000} delay={0} separator="," easingFn={easeInOutCubic}>
          {({ countUpRef }) => <span className={style.num} ref={countUpRef} />}
        </CountUp> */}
        <span>严控货源&nbsp;&nbsp;品质保证</span>
      </span>
    </div>
    <div className={style.brand_update}>
      <div className={style.brand_bg} />
      <img
        alt="爱回收严选"
        src="//img10.360buyimg.com/paipai/jfs/t1/52059/14/23335/129978/63f5b96eF5d087f01/5e8094ea93828efe.png"
      />
    </div>
  </div>
);

export default memo(InspectTitle);
