import { getJFSImageURL } from '@pai/paipai-util';

// 是否支持webp
export const isSupportWebp = (() => {
  try {
    return (
      document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0
    );
  } catch (err) {
    return false;
  }
})();

/**
 * 分隔数组
 * @param {*} list 原数组
 * @param {*} step 每组数
 * @returns
 */
export const splitArry = (list = [], step = 1) => {
  const result = [];
  for (let i = 0; i < list.length / step; i += 1) {
    result.push(list.slice(i * step, (i + 1) * step));
  }
  return result;
};

/**
 * 获取uuid
 * @returns {string}
 */
export const uuid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
   const r = (Math.random() * 16) | 0; // eslint-disable-line
   const v = c === 'x' ? r : (r & 0x3) | 0x8; // eslint-disable-line
    return v.toString(16);
  });

/**
 * @description 修复JSF图片链接地址
 * @export
 * @param {*} url
 * @param {*} width
 * @param {*} height
 */
export function fixJFSImageURL({
  domain = 'img10.360buyimg.com',
  webp = isSupportWebp,
  bizname = 'ppershou',
  url = '',
  width,
  height,
  quality,
  cut = { width, height },
}) {
  // eslint-disable-next-line no-underscore-dangle
  let _url = url || '';
  if (_url === '') {
    return _url;
  }

  if (/^http/.test(_url) || _url.match(/.com|.cn/)) {
    return _url;
  }
  // 从完整图片地址中截取出jfs原始图片地址
  _url = _url.slice(_url.indexOf('jfs/'));

  // 获取jfs查看图片地址
  _url = getJFSImageURL({
    url: _url,
    width,
    height,
    domain,
    bizname,
    cut: cut || {
      width,
      height,
    },
    quality,
    webp,
  });

  return _url;
}

/**
 * 获取当前target滚动位置
 * @param {*} target 要获取的元素
 * @param {*} top 是否垂直位置
 * @returns
 */
export function getScroll(target, top) {
  const prop = top ? 'pageYOffset' : 'pageXOffset';
  const method = top ? 'scrollTop' : 'scrollLeft';
  const isWindow = target === window;

  let ret = isWindow ? Window[prop] : target[method];
  // ie6,7,8 standard mode
  if (isWindow && typeof ret !== 'number') {
    ret = document.documentElement[method];
  }

  return ret;
}

export function easeInOutCubic(t, b, c, d) {
  const cc = c - b;
  // eslint-disable-next-line no-param-reassign
  t /= d / 2;
  if (t < 1) {
    return (cc / 2) * t * t * t + b;
  }
  // eslint-disable-next-line no-param-reassign
  const r = (cc / 2) * ((t -= 2) * t * t + 2) + b;
  return r;
}

/**
 * 滚动到y
 * @param {*} y
 * @param {*} options
 */
export function scrollTo(y, options = {}) {
  const raf = window.requestAnimationFrame || window.setTimeout;
  const { getContainer = () => window, callback, duration = 450 } = options;

  const container = getContainer();
  const scrollTop = getScroll(container, true);
  const startTime = Date.now();

  const frameFunc = () => {
    const timestamp = Date.now();
    const time = timestamp - startTime;
    const nextScrollTop = easeInOutCubic(time > duration ? duration : time, scrollTop, y, duration);
    if (container === window) {
      window.scrollTo(window.pageXOffset, nextScrollTop);
    } else {
      container.scrollTop = nextScrollTop;
    }
    if (time < duration) {
      raf(frameFunc);
    } else if (typeof callback === 'function') {
      callback();
    }
  };
  raf(frameFunc);
}

/**
 * 节流2
 * @param {*} func
 * @param {*} wait
 */
export const throttleBySetTimeOut = (func, wait) => {
  let timeout2;
  return function back(...parms) {
    const context = this;
    if (!timeout2) {
      timeout2 = setTimeout(() => {
        timeout2 = null;
        func.apply(context, parms);
      }, wait);
    }
  };
};
