import React, { memo } from 'react';
import style from './index.module.scss';

/**
 * 高价回收
 * @returns
 */
const HuiShouSection = () => (
  <div className={`${style.huishou} wrap`} id="huishou">
    <div className="content">
      <div className={style.title}>
        <span className={style.highRecover}>高价回收</span>
        <div className={style['title-sub']}>
          <span className={style['sub-text']}>56个品类放心卖 二手轻松换成钱</span>
        </div>
      </div>
      <img
        className={style.bg}
        src="//img10.360buyimg.com/paipai/jfs/t1/199075/30/3456/1183038/611a0b40E77d93c3b/db85f8a27db0d708.png"
        alt="高价回收"
      />
    </div>
  </div>
);

export default memo(HuiShouSection);
