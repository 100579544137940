import React, { memo, useCallback, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
// Lazy
import { uuid } from '@/utils/tools';
import Tab from '@/components/tab';
import style from './index.module.scss';

SwiperCore.use([Autoplay, Navigation]);
// Lazy,

const swiperList = [
  {
    tabName: '溯源好货',
    img: '//img10.360buyimg.com/paipai/jfs/t1/149862/9/37270/83078/64b60231F25cbea38/cbf861bdea4acc52.png',
  },
  {
    tabName: '专业质检',
    img: '//img10.360buyimg.com/paipai/jfs/t1/156813/36/37972/235555/64b60233Fe2a824fe/ab33903a6adf46d9.png',
  },
  {
    tabName: '正规发票',
    img: '//img10.360buyimg.com/paipai/jfs/t1/181689/20/35387/90345/64b60234Fd823a3cf/4b6575a47e3b5cc6.png',
  },
  {
    tabName: '千家门店联保',
    img: '//img10.360buyimg.com/paipai/jfs/t1/141300/34/37477/217889/64b60234F508a79f4/2fce0a8c00285e8a.png',
  },
  {
    tabName: '七天可退',
    img: '//img10.360buyimg.com/paipai/jfs/t1/158157/33/38947/104156/64b60233F911cc37a/a5a6528784fbfec7.png',
  },
  {
    tabName: '一年质保',
    img: '//img10.360buyimg.com/paipai/jfs/t1/173870/34/36757/137369/64b60232Ffa3169ef/de6ac79c84997d9e.png',
  },
].map((item) => ({ ...item, _uuid: uuid() }));

const tabList = swiperList.map((item) => ({
  name: item.tabName,
  id: item._uuid,
}));

const InspectSwiper = () => {
  const swiperBigRef = useRef();

  const [currentIndex, setCurrentIndex] = useState(0);

  const tabChange = useCallback((item, index) => {
    if (swiperBigRef.current?.slideTo) {
      swiperBigRef.current.slideTo(index + 1);
    }
  }, []);

  const onActiveIndexChange = useCallback((_swiper) => {
    setCurrentIndex(_swiper?.realIndex || 0);
  }, []);

  return (
    <div className={style.swiper_big}>
      <div className={style.swiper_tab}>
        <Tab list={tabList} defaultIndex={currentIndex} onChange={tabChange} />
      </div>
      <div className={style.swiper}>
        <Swiper
          loop
          navigation={{
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
          }}
          lazy={{
            loadOnTransitionStart: true,
            loadPrevNext: true,
          }}
          onAfterInit={(theSwiper) => {
            swiperBigRef.current = theSwiper;
          }}
          onActiveIndexChange={onActiveIndexChange}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
        >
          {swiperList.map((item) => (
            <SwiperSlide key={item._uuid}>
              <div key={item._uuid} className={`${style['swiper-item']} swiper-slide`}>
                <img src={item.img} alt={item.tabName} className="swiper-lazy" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={style.swiper_btnbox}>
        <div
          role="button"
          tabIndex="0"
          className={`${style.swiper_btn} ${style.pre_btn} swiper-prev`}
        >
          <span />
        </div>

        <div
          role="button"
          tabIndex="0"
          className={`${style.swiper_btn} ${style.next_btn} swiper-next`}
        >
          <span />
        </div>
      </div>
    </div>
  );
};

export default memo(InspectSwiper);
