import React, { memo } from 'react';
import InspectSwiper from './inspectSwiper';
import CommentsSwiper from './commentsSwiper';
// import InspectVideo from './inspectVideo';
import InspectTitle from './inspectTitle';
import InspectCategory from './inspectCategory';
import style from './index.module.scss';

/**
 * 爱回收严选
 * @returns
 */
const InspectSection = () => (
  <div className={`${style.inspect} wrap`} id="inspect">
    <div className={style.swiper_bg}>
      <div className="content">
        <InspectTitle />
        <InspectSwiper />
        <CommentsSwiper />
      </div>
    </div>
    <div className={style.inspect_bottom}>
      <div className="content">
        {/* <InspectVideo /> */}
        <InspectCategory />
      </div>
    </div>
  </div>
);
export default memo(InspectSection);
